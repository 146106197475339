@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: "Euclid";
  src: url("../public/assets/fonts/Euclid/Euclid\ Circular\ A\ Light_0.ttf")
    format("truetype");
}

:where([class*="-11xg00t"]).ant-input-outlined:focus-within {
  border-color: white;
  box-shadow: none;
  outline: 0;
}

:where([class*="-11xg00t"]).ant-input-outlined:focus {
  box-shadow: none;
}

:hover .car {
  animation: slidein 2s;
}

@keyframes slidein {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0%);
  }
}

:where([class*="-11xg00t"]).ant-input-outlined:focus-within {
  border-color: #d4d4d4;
  box-shadow: none;
  outline: 0;
}

.styledPagination {
  width: max-content;
  border: 1px solid #e6e9ec !important;
  border-radius: 5px;
  font-family: "Euclid", sans-serif !important;
  overflow: hidden;
  height: fit-content !important;
}

.styledPagination * {
  font-family: "Euclid", sans-serif !important;
}

.styledPagination .ant-pagination-prev {
  padding: 5px 20px;
  height: auto;
  color: #b0b0b0;
  margin: 0;
  border-radius: 0;
  font-weight: 300;
}
.styledPagination .ant-pagination-item {
  padding: 5px 10px;
  height: auto;
  font-weight: 300;
  border-radius: 0;
  margin: 0;
  color: #b0b0b0;
  border: none;
  border-left: 1px solid #e6e9ec !important;
}

.styledPagination .ant-pagination-item-active {
  background-color: #997951 !important;
  color: white !important;
  border: 1px solid #997951 !important;
}

.styledPagination .ant-pagination-item-active * {
  color: white !important;
}

.styledPagination .ant-pagination-item-active:hover {
  background-color: #997951 !important;
  border-left: 1px solid #997951 !important;
  color: white !important;
}

.styledPagination .ant-pagination-item-active:hover * {
  color: white !important;
}

.styledPagination .ant-pagination-item:hover {
  background-color: #997951 !important;
  border: 1px solid #997951 !important;
  color: white !important;
}

.styledPagination .ant-pagination-item:hover * {
  color: white !important;
}

.styledPagination .ant-pagination-next {
  padding: 5px 20px;
  height: auto;
  margin: 0;
  border-radius: 0;
  color: #b0b0b0;
  font-weight: 300;
  border-left: 1px solid #e6e9ec !important;
}

.styledPagination .ant-pagination-next:hover {
  background-color: #997951;
  border-left: 1px solid #997951 !important;
  color: white !important;
}

.styledPagination .ant-pagination-prev:hover {
  background-color: #997951;
  color: #fff;
}

.banner .alice-carousel img {
  width: 100%;
}

.homeCard {
  background-color: rgba(0, 0, 0, 0.3);
}

.homeCard img {
  max-width: 90px;
}

.verification-input {
  border: 1px solid gray;
  border-radius: 4px;
  background-color: #d4d4d4;
  width: 5rem;
  height: 3rem;
  font-size: 1rem;
  text-align: center;
  margin-right: 1rem;
}

.verification-input-focused {
  border-color: darkgray;
}

:where([class*="-11xg00t"]).ant-divider-horizontal {
  margin: 0;
}

.styledPagination {
  width: max-content;
  border: 1px solid #e6e9ec !important;
  border-radius: 5px;
  font-family: "Euclid", sans-serif !important;
  overflow: hidden;
  height: fit-content !important;
}

.styledPagination * {
  font-family: "Euclid", sans-serif !important;
}

.styledPagination .ant-pagination-prev {
  padding: 5px 20px;
  height: auto;
  color: #b0b0b0;
  margin: 0;
  border-radius: 0;
  font-weight: 300;
}
.styledPagination .ant-pagination-item {
  padding: 5px 10px;
  height: auto;
  font-weight: 300;
  border-radius: 0;
  margin: 0;
  color: #b0b0b0;
  border: none;
  border-left: 1px solid #e6e9ec !important;
}

.styledPagination .ant-pagination-item-active {
  background-color: #d7b65d !important;
  color: white !important;
  border: 1px solid #d7b65d !important;
}

.styledPagination .ant-pagination-item-active * {
  color: white !important;
}

.styledPagination .ant-pagination-item-active:hover {
  background-color: #d7b65d !important;
  border-left: 1px solid #d7b65d !important;
  color: white !important;
}

.styledPagination .ant-pagination-item-active:hover * {
  color: white !important;
}

.styledPagination .ant-pagination-item:hover {
  background-color: #d7b65d !important;
  border: 1px solid #d7b65d !important;
  color: white !important;
}

.styledPagination .ant-pagination-item:hover * {
  color: white !important;
}

.styledPagination .ant-pagination-next {
  padding: 5px 20px;
  height: auto;
  margin: 0;
  border-radius: 0;
  color: #b0b0b0;
  font-weight: 300;
  border-left: 1px solid #e6e9ec !important;
}

.styledPagination .ant-pagination-next:hover {
  background-color: #d7b65d;
  border-left: 1px solid #d7b65d !important;
  color: white !important;
}

.styledPagination .ant-pagination-prev:hover {
  background-color: #d7b65d;
  color: #fff;
}

.selection
  :where([class*="-11xg00t"]).ant-select-single.ant-select-show-arrow
  .ant-select-selection-item {
  padding: 0px 1.5rem;
}

.custom-option {
  background: url("../public/assets/images/pointer.png") left no-repeat;
  background-position: 10px center;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
}

:where([class*="-11xg00t"]).ant-select-dropdown
  .ant-select-item-option-content {
  padding: 0px 20px;
}

:where([class*="-11xg00t"]).ant-picker .ant-picker-input > input {
  padding: 0px 20px;
}

:where([class*="-11xg00t"]).ant-picker
  .ant-picker-input
  > input:placeholder-shown {
  padding: 0px 20px;
}

:where([class*="-11xg00t"]).ant-picker-outlined:hover {
  border: #d7b65d;
}

:where([class*="-11xg00t"]).ant-picker-outlined:focus,
:where([class*="-11xg00t"]).ant-picker-outlined,
:where([class*="-11xg00t"]).ant-picker-outlined:hover,
:where([class*="-11xg00t"]).ant-picker-outlined:focus-within {
  background: #e9e9e9;
  border: #d7b65d;
}

:where([class*="-11xg00t"]).ant-select-outlined:not(.ant-select-customize-input)
  .ant-select-selector {
  background: #e9e9e9;
  border: #d7b65d;
}

:where([class*="-11xg00t"]).ant-switch.ant-switch-checked:hover:not(
    .ant-switch-disabled
  ),
:where([class*="-11xg00t"]).ant-switch.ant-switch-checked {
  background: #ce9a09;
}

@media only screen and (max-width: 1280px) {
  .homeCard {
    background-color: rgba(0, 0, 0, 1);
  }

  .iconCard img {
    height: 30px;
  }
}

@media only screen and (max-width: 1000px) {
  .homeHeading {
    line-height: 12px !important;
    margin-top: 40px !important;
  }
}

@media only screen and (max-width: 580px) {
  .homeCard {
    display: none;
  }
  .verification-input {
    width: 3rem;
    height: 3rem;
    font-size: 1rem;
  }

  .verification-input-focused {
    border-color: darkgray;
  }
  .styledPagination {
    font-size: 10px !important;
  }
}

/* Add this custom CSS */
.iconCard {
  transition: transform 0.3s ease-in-out; /* Smooth transition for the zoom effect */
}

.iconCard:hover {
  transform: scale(1.1); /* Scale up the element on hover */
}

/* Add this custom CSS */
.blogCard {
  transition: transform 0.3s ease-in-out; /* Smooth transition for the zoom effect */
}

.blogCard:hover {
  transform: scale(1.1); /* Scale up the element on hover */
}

/* Add this custom CSS */
.aboutCard {
  transition: transform 0.3s ease-in-out; /* Smooth transition for the zoom effect */
}

.aboutCard:hover {
  transform: scale(1.05); /* Scale up the element on hover */
}

/* .ant-input::placeholder {
  color: red !important;
}
input[type="date"]:invalid::-webkit-datetime-edit {
  color: red !important;
} */
.ant-select-dropdown .ant-select-item-option-content {
  padding: 0px 0px !important;
}

.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #d9d9d9;
  background-color: #f3f4f6;
  border-radius: 8px;
}

.customShadow {
  --tw-shadow: 0px -1px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.customCaraousel .alice-carousel__dots {
  padding: 0;
  list-style: none;
  text-align: center;
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 30px; /* Adjust the spacing between dots as needed */
}

.customCaraousel .alice-carousel__dots > li {
  border: 2px solid white; /* Adjust border properties as needed */
  border-radius: 50%; /* Ensures the border creates a circle */
  width: 30px; /* Adjust dot size */
  height: 30px; /* Adjust dot size */
  padding: 2px;
  background-color: transparent !important;
}

.customCaraousel .alice-carousel__dots-item {
  position: relative;
}

.customCaraousel .alice-carousel__dots-item::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.alice-carousel__dots-item:not(.__custom).__active::before {
  background-color: #d7b65d;
}

.customCaraousel2 .alice-carousel__dots-item:not(.__custom).__active  {
  background-color: #d7b65d !important;
}

.alice-carousel__dots-item:hover::before {
  background-color: #d7b65d;
}

.customCaraousel .alice-carousel__dots-item:not(.__custom).__active {
  /* background-color: #d7b65d !important; */
}

@media only screen and (max-width: 1700px) {
  .customCaraousel .customCaraousel .alice-carousel__dots {
    right: 10px;
    gap: 10px; /* Adjust the spacing between dots as needed */
  }
}

@media only screen and (max-width: 1200px) {
  .customCaraousel .alice-carousel__dots {
    right: 0;
    gap: 5px; /* Adjust the spacing between dots as needed */
  }

  .customCaraousel .alice-carousel__dots-item::before { 
    width: 6px;
    height: 6px;
  }
  .customCaraousel .alice-carousel__dots > li { 
    width: 12px;
    height: 12px;
  }
}
